import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["container", "overlay", "panel"]

  connect() { 
    this.show()
  }

  show() {
    this.containerTarget.classList.remove('hidden')
    enter(this.overlayTarget)
    enter(this.panelTarget)
  }

  hide() {
    leave(this.panelTarget)
    leave(this.overlayTarget).then(() => {
      this.containerTarget.classList.add('hidden')
    })
    this.element.remove()
  }
}