import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldset"]

  connect() {
  }

  unselectEmployee(event) {
    this.fieldsetTargets.forEach((el, i) => {
      el.querySelector("input[name$='[employee_id]']").value = null
    })
  }

  selectEmployee(event) {
    let employeeId = event.target.value

    this.fieldsetTargets.forEach((el, i) => {
      el.querySelector("input[name$='[employee_id]']").value = employeeId

      let employeeDuration = el.dataset[`employeeOption-${employeeId}Duration`]
      let retailPrice = el.dataset[`employeeOption-${employeeId}RetailPrice`]

      el.querySelector("input[name$='[duration]']").value = employeeDuration
      el.querySelector("input[name$='[retail_price]']").value = retailPrice

      el.querySelector("span[role='retail-price']").innerHTML = retailPrice
      el.querySelector("span[role='duration']").innerHTML = employeeDuration
    })
  }

  toggleService(event) {
    event.target.closest("fieldset").classList.toggle("bg-pink-200")
  }
}