import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends Controller {
  static targets = ['input']

  connect() {
    new autoComplete({
      data: {
        src: async () => {
          const source = await fetch(`/search?term=${encodeURI(this.inputTarget.value)}`);
          const data = await source.json();

          return data;
        },
        key: ["name", "phone_number"],
        cache: false
      },
      selector: `#${this.inputTarget.id}`,
      threshold: 2,
      maxResults: 7,
      debounce: 300,
      resultsList: {
        render: true
      },
      resultItem: {
        content: (data, source) => {
          source.innerHTML = `<div class="font-medium">${data.value.name}</div><span class="text-xs">${data.value.phone_number}</span>`;
        },
        element: "li"
      },

      onSelection: item => {
        Turbo.visit(item.selection.value.location)
      }
    });
  }
}